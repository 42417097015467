import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const OurPeople = () => {
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [filter, setFilter] = useState("All");

  const navigate = useNavigate();

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.substring(0, num) + "...";
    } else {
      return str;
    }
  };

  useEffect(() => {
    if (filter === "All") {
      setFilteredMembers(members);
    } else if (filter === "Partners") {
      setFilteredMembers(
        members.filter((member) => member.title.includes("Partner"))
      );
    } else if (filter === "Senior Associates") {
      setFilteredMembers(
        members.filter((member) => member.title.includes("Senior Associate"))
      );
    } else if (filter === "Associates") {
      setFilteredMembers(
        members.filter((member) => member.title === "Associate")
      );
    } else if (filter === "Consultants") {
      setFilteredMembers(
        members.filter((member) => member.title === "Consultant")
      );
    }
  }, [filter]);

  useEffect(() => {
    fetch("/members.json")
      .then((response) => response.json())
      .then((data) => setMembers(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    setFilteredMembers(members);
  }, [members]);

  return (
    <>
      <section className="relative w-full pt-40 pb-44 bg-center bg-cover bg-[url('/public/images/our-people.png')]">
        <div className="w-full mt-28 flex flex-col items-center font-bold lg:px-44 md:px-20 px-6">
          <h1 className="font-manrope lg:text-4xl md:text-3xl text-2xl text-white text-center">
            OUR PEOPLE
          </h1>
        </div>

        <div className="bg-white flex flex-row flex-wrap gap-2 justify-center shadow-sm md:p-5 p-3 absolute md:-bottom-12 -bottom-20 left-1/2 transform -translate-x-1/2 z-10 rounded-3xl md:text-base text-xs">
          {[
            "All",
            "Partners",
            "Senior Associates",
            "Associates",
            "Consultants",
          ].map((item, index) => (
            <div
              key={index}
              onClick={() => setFilter(item)}
              className={
                (filter === item
                  ? "text-white bg-red-500 border-2 border-red-500 "
                  : "text-gray-500 border-2 border-gray-300 ") +
                "hover:bg-red-500 hover:border-red-500 hover:border-2 hover:text-white rounded-full py-2 px-5 text-center cursor-pointer"
              }
            >
              {item}
            </div>
          ))}
        </div>
      </section>

      <section className="relative bg-slate-100 md:py-14 py-24 lg:px-14 md:px-8 px-4 flex flex-col items-center space-y-10 font-montserrat-semibold">
        <div className="flex flex-col items-center">
          {filteredMembers.map((member, index) => (
            <div
              key={index}
              className="lg:w-2/3 w-4/5 flex md:flex-row flex-col items-center bg-white rounded-lg p-6 shadow-xl my-4"
            >
              <img
                alt={member.name}
                className="md:w-1/4 w-full md:mb-0 mb-2 bg-white border-[0.5px] rounded-xl shadow-inner"
                src={member.image}
              />
              <div className="flex flex-col justify-center md:pl-6">
                <p
                  onClick={() =>
                    navigate("/member", { state: { member: member } })
                  }
                  className="text-lg font-bold mb-2 cursor-pointer uppercase"
                >
                  {member.name}
                </p>
                <p className="text-xs text-red-500 uppercase">{member.title}</p>
                {member.notice && (
                  <p className="mt-2 -mb-2 text-xs text-black uppercase">
                    {member.notice}
                  </p>
                )}
                <p
                  className="text-gray-500 mt-4 text-xs"
                  dangerouslySetInnerHTML={{
                    __html: truncateString(member.description, 632),
                  }}
                ></p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default OurPeople;
