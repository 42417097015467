import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const [blogs, setBlogs] = useState({});

  const navigate = useNavigate();

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.substring(0, num) + "...";
    } else {
      return str;
    }
  };

  useEffect(() => {
    fetch("/blogs.json")
      .then((response) => response.json())
      .then((data) => setBlogs(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <>
      <section className="w-full pt-44 pb-20 bg-center bg-cover bg-[url('/public/images/home.jpg')]">
        <div className="w-full mt-10 flex flex-col items-center font-bold lg:px-44 md:px-20 px-6">
          <div className="py-44 flex items-center justify-center">
            <h1 className="w-full font-manrope lg:text-4xl md:text-4xl text-2xl text-white text-center uppercase">
              Under construction
            </h1>
          </div>
          {/* <span className="text-red-500 mt-3 mb-6 text-xs font-montserrat-semibold">
            BLOG
          </span> */}
          {/* <h1 className="w-[48%] font-manrope lg:text-4xl md:text-4xl text-2xl text-white md:text-start text-center uppercase">
            {Object.keys(blogs).length > 0 && blogs["5"]["title"]}
          </h1>
          <p className="md:w-1/2 mt-6 font-montserrat-semibold text-gray-400 text-sm tracking-wide md:text-start text-center">
            {Object.keys(blogs).length > 0 &&
              truncateString(blogs["5"]["content"], 300)}
          </p>
          <p
            onClick={() => navigate("/blog", { state: { blog: blogs["5"] } })}
            className="cursor-pointer mt-6 font-montserrat text-white text-sm tracking-wide flex items-center"
          >
            Read More
            <i
              className="fa fa-angle-right text-white ml-2"
              style={{ fontSize: "20px" }}
            ></i>
          </p> */}
        </div>
      </section>

      <section className="bg-slate-100 p-10 flex flex-col items-center space-y-4">
        <h1 className="text-2xl font-montserrat-semibold py-10">
          No blogs yet!
        </h1>
        {/* {Object.keys(blogs).length > 0 &&
          Object.keys(blogs).map((key, index) => (
            <div className="card lg:w-2/3 w-full flex md:flex-row flex-col items-center bg-white rounded-lg p-6 shadow-xl">
              <img
                className="md:w-1/3 w-full md:mb-0 mb-2 rounded-lg"
                src={blogs[key]["image-small"]}
              />
              <div className="flex flex-col justify-center md:pl-6">
                <p className="text-xs text-red-500 font-montserrat-semibold mb-2">
                  {blogs[key]["date"]}
                </p>
                <h2 className="text-base font-montserrat-extrabold mb-2 uppercase">
                  {blogs[key]["title"]}
                </h2>
                <p className="text-gray-500 text-xs mb-3">
                  {truncateString(blogs[key]["content"], 350)}
                </p>

                <p
                  onClick={() =>
                    navigate("/blog", { state: { blog: blogs[key] } })
                  }
                  className="text-xs font-monstserrat font-bold tracking-wide mb-2 flex items-center"
                >
                  <span className="mt-[0.5px] cursor-pointer">Read More</span>
                  <i
                    className="fa fa-angle-right text-black ml-2"
                    style={{ fontSize: "20px" }}
                  ></i>
                </p>
              </div>
            </div>
          ))} */}
      </section>
    </>
  );
};

export default Blogs;
