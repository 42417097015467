import React from "react";
import { useLocation } from "react-router-dom";

const Blog = () => {
  const location = useLocation();
  const blog = location.state ? location.state.blog : null;

  return (
    <>
      <section className="w-full py-20 bg-cover bg-black"></section>

      <section className="font-montserrat-semibold">
        <div className="w-full relative p-48 bg-cover bg-center bg-[url('/public/images/single-blog.png')]">
          <div className="absolute -bottom-10 left-[10%] bg-white md:px-10 px-5 md:py-5 py-3 shadow-sm">
            <span className="text-red-500 font-bold text-sm mb-5">
              {blog &&
                new Date(
                  blog["date"].split("/").reverse().join("-")
                ).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
            </span>
            <p className="mt-2 font-bold tracking-wide mb-1 uppercase">
              {blog && blog["title"]}
            </p>
            <p className="text-gray-500 text-xs text-right uppercase">
              {blog && blog["author"]}
            </p>
          </div>
        </div>

        <div
          className="bg-slate-100 z-5 py-20 md:px-20 px-10 text-gray-500 text-sm"
          dangerouslySetInnerHTML={{ __html: blog ? blog["content"] : "" }}
        ></div>
      </section>
    </>
  );
};

export default Blog;
