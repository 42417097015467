import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const PracticeAreas = () => {
  const [filter, setFilter] = useState("All");

  const navigate = useNavigate();

  const corporate_services = {
    "Corporate & Commercial": "Corporate & Commercial",
    "Banking & Finance": "Banking & Finance",
    "Telecommunication & Technology": "Telecommunication & Technology",
    "Public-Private Partnership (PPP) Law":
      "Public-Private Partnership Projects",
    Employment: "Employment",
    "Property Law": "Others",
    "Admiralty Law": "Others",
    "Aviation Law": "Others",
    "Insurance Law": "Others",
    "Wealth Management": "Others",
  };

  const litigation_services = {
    "Company & Commercial": "Company & Commercial",
    "Taxation & Customs": "Taxation & Customs",
    "Constitutional law": "Constitutional law",
  };

  return (
    <>
      <section className="w-full pt-40 pb-20 bg-center bg-cover bg-[url('/public/images/career.png')]">
        <div className="w-full mt-10 flex flex-col items-center font-bold lg:px-44 md:px-20 px-6">
          <h1 className="font-manrope lg:text-4xl md:text-3xl text-2xl text-white text-center">
            OUR PRACTICE AREAS
          </h1>
          <p className="md:w-1/2 mt-6 font-montserrat-semibold text-gray-400 text-xs tracking-wide text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </section>

      <section className="bg-slate-100 py-14 lg:px-14 md:px-8 px-4 flex md:flex-row flex-col md:justify-between font-montserrat-semibold">
        <div className="text-xs md:w-[30%] w-full mb-5 md:mx-0 mx-auto">
          <div className="flex items-center mb-2">
            <p className="text-gray-500 font-semibold">FILTER BY</p>
            <div className="flex-grow border-t-2 border-gray-300 ml-2"></div>
          </div>
          <button
            onClick={() => setFilter("All")}
            className={
              (filter === "All"
                ? "border-black bg-black text-white "
                : "border-gray-300 ") +
              "w-full mb-2 py-4 px-8 border-2  hover:border-black hover:bg-black hover:text-white"
            }
          >
            All
          </button>
          <div className="grid grid-cols-2 gap-2">
            <button
              onClick={() => setFilter("Corporate")}
              className={
                (filter === "Corporate"
                  ? "border-black bg-black text-white "
                  : "border-gray-300 ") +
                "py-4 lg:px-8 px-auto border-2 hover:border-black hover:bg-black hover:text-white"
              }
            >
              Corporate
            </button>
            <button
              onClick={() => setFilter("Litigation")}
              className={
                (filter === "Litigation"
                  ? "border-black bg-black text-white "
                  : "border-gray-300 ") +
                "py-4 lg:px-8 px-auto border-2 hover:border-black hover:bg-black hover:text-white"
              }
            >
              Litigation
            </button>
          </div>
        </div>

        <div className="md:w-[65%] w-full md:mx-0 mx-auto grid grid-cols-2 gap-4 font-semibold text-xs text-gray-600">
          {(filter === "All" || filter === "Corporate") &&
            Object.keys(corporate_services).map((service, index) => (
              <div
                key={index}
                onClick={() =>
                  navigate("/service", {
                    state: {
                      service: corporate_services[service],
                      category: "corporate",
                    },
                  })
                }
                className="uppercase bg-white px-10 py-4 hover:text-white hover:bg-red-500 flex items-center md:justify-start justify-center md:text-start text-center cursor-pointer"
              >
                {service}
              </div>
            ))}
          {(filter === "All" || filter === "Litigation") &&
            Object.keys(litigation_services).map((service, index) => (
              <div
                key={index}
                onClick={() =>
                  navigate("/service", {
                    state: {
                      service: litigation_services[service],
                      category: "litigation",
                    },
                  })
                }
                className="uppercase bg-white px-10 py-4 hover:text-white hover:bg-red-500 flex items-center md:justify-start justify-center md:text-start text-center cursor-pointer"
              >
                {service}
              </div>
            ))}
        </div>
      </section>
    </>
  );
};

export default PracticeAreas;
