import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="bg-black text-white font-montserrat-semibold">
      <div className="container mx-auto px-4 py-20">
        <div className="flex md:flex-row flex-col items-center">
          <div className="flex items-center justify-center mb-4 md:mb-0">
            <img
              className="w-3/4 md:pr-24 md:mb-0 mb-10"
              src="images/logo.png"
              alt="logo"
            />
          </div>

          <div className="text-sm flex md:flex-row flex-col md:text-start text-center lg:space-x-32 md:space-x-10 md:space-y-0 space-y-10">
            <div className="flex flex-col md:justify-start justify-center space-y-3">
              <p className="text-white font-semibold">Our Firm</p>
              <p
                onClick={() => navigate("/our-people")}
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                People
              </p>
              <p
                onClick={() => navigate("/career")}
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                Career
              </p>
              <p
                onClick={() => navigate("/blogs")}
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                Blogs
              </p>
              <p
                onClick={() => navigate("/contact")}
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                Contact
              </p>
              <p
                onClick={() => navigate("/privacy-policy")}
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                Privacy Policy
              </p>
            </div>

            <div className="flex flex-col md:justify-start justify-center space-y-3">
              <p className="text-white font-semibold">Corporate Services</p>
              <p
                onClick={() =>
                  navigate("/service", {
                    state: {
                      service: "Banking & Finance",
                      category: "corporate",
                    },
                  })
                }
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                Banking & Finance
              </p>
              <p
                onClick={() =>
                  navigate("/service", {
                    state: {
                      service: "Corporate & Commercial",
                      category: "corporate",
                    },
                  })
                }
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                Corporate & Commercial
              </p>
              <p
                onClick={() =>
                  navigate("/service", {
                    state: {
                      service: "Employment",
                      category: "corporate",
                    },
                  })
                }
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                Employment
              </p>
              <p
                onClick={() =>
                  navigate("/service", {
                    state: {
                      service: "Public-Private Partnership Projects",
                      category: "corporate",
                    },
                  })
                }
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                Public-Private Partnership Projects
              </p>
              <p
                onClick={() =>
                  navigate("/service", {
                    state: {
                      service: "Telecommunication & Technology",
                      category: "corporate",
                    },
                  })
                }
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                Telecommunication & Technology
              </p>
            </div>

            <div className="flex flex-col md:justify-start justify-center space-y-3">
              <p className="text-white font-semibold">Litigation Services</p>
              <p
                onClick={() =>
                  navigate("/service", {
                    state: {
                      service: "Company & Commercial",
                      category: "litigation",
                    },
                  })
                }
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                Company & Commercial
              </p>
              <p
                onClick={() =>
                  navigate("/service", {
                    state: {
                      service: "Constitutional law",
                      category: "litigation",
                    },
                  })
                }
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                Constitutional law
              </p>
              <p
                onClick={() =>
                  navigate("/service", {
                    state: {
                      service: "Taxation & Customs",
                      category: "litigation",
                    },
                  })
                }
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                Taxation & Customs
              </p>
            </div>

            <div className="flex flex-col md:justify-start justify-center space-y-3">
              <p className="text-white font-semibold">Our History</p>
              <p
                onClick={() => navigate("/founder")}
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                About the Founder
              </p>
              <p
                onClick={() => navigate("/our-people")}
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                People
              </p>

              <p
                onClick={() => navigate("/")}
                className="text-gray-500 hover:text-white cursor-pointer"
              >
                Home
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className="text-white text-center text-sm font-montserrat pb-2">
        All Copyrights reserved by Syed Ishtiaq Ahmed & Associates
      </p>
    </footer>
  );
};

export default Footer;
