import React from "react";

const DisclaimerModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
        <h2 className="text-lg font-bold mb-4">Disclaimer</h2>
        <p className="text-sm text-gray-700 mb-4">
          The Bar Council of Bangladesh strictly prohibits all forms of
          advertising and solicitation by legal practitioners. By accessing this
          website, www.siaalaw.com, you acknowledge and confirm that you are
          seeking information regarding Syed Ishtiaq Ahmed & Associates (SIA&A)
          of your own accord, without any solicitation, advertisement, or
          inducement from SIA&A or its members. The content provided on this
          website is intended solely for informational purposes and should not
          be construed as solicitation or advertising. Kindly note that certain
          content on this website, including videos, may be owned by third
          parties and not by SIA&A. No content or information on this website
          should be regarded as legal advice. SIA&A accepts no liability for any
          actions taken based on the content or information provided herein. The
          intellectual property rights to the content created by SIA&A on this
          website are owned exclusively by SIA&A.
        </p>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerModal;
