import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "../slick-styles/slick.css";
import "../slick-styles/slick-theme.css";

const BlogSlider = () => {
  const [blogs, setBlogs] = useState({});

  const sliderRef = useRef(null);
  const navigate = useNavigate();

  const next = () => {
    sliderRef.current.slickNext();
  };

  const prev = () => {
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    fetch("/blogs.json")
      .then((response) => response.json())
      .then((data) => setBlogs(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="relative bg-slate-100 py-10 font-montserrat-semibold flex justify-center">
      <div className="md:w-5/6 w-3/5">
        <div className="flex justify-between mb-5">
          <p className="font-semibold">LATEST NEWS FROM</p>
          <p className="flex items-center text-gray-600 text-xs font-semibold cursor-pointer">
            <p
              onClick={() => navigate("/blogs")}
              className="cursor-pointer mt-[1px] md:pl-0 pl-6"
            >
              OUR BLOGS
            </p>
            <i
              className="fa fa-angle-right text-gray-500 md:ml-2"
              style={{
                fontSize: "20px",
              }}
            ></i>
          </p>
        </div>

        <Slider ref={sliderRef} {...sliderSettings}>
          {Object.keys(blogs).map((key, index) => (
            <div key={index} className={`relative h-full p-2`}>
              <div className="relative px-5 pt-80 pb-5 z-20">
                <div className="absolute bottom-5 left-0 px-5">
                  <p className="text-sm text-white mb-2">
                    {blogs[key]["title"]}
                  </p>
                  <p
                    onClick={() =>
                      navigate("/blog", { state: { blog: blogs[key] } })
                    }
                    className="inline-block cursor-pointer text-sm text-red-500 pb-[1.5px] border-b-[1px] border-red-500"
                  >
                    Read more
                  </p>
                </div>
              </div>

              <img
                className="absolute object-cover p-2 -top-10 left-0 h-full z-0"
                src={blogs[key]["image-small"]}
              />
              <div
                className="absolute inset-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 20%, transparent)",
                }}
              ></div>
            </div>
          ))}
        </Slider>

        <span
          onClick={next}
          className="absolute lg:right-10 right-4 top-[45%] bg-black px-4 py-2 mt-5 cursor-pointer"
        >
          <i
            className="fa fa-angle-right text-white"
            style={{
              fontSize: "30px",
            }}
          ></i>
        </span>
        <span
          onClick={prev}
          className="absolute lg:left-10 left-4 top-[45%] bg-black px-4 py-2 mt-5 cursor-pointer"
        >
          <i
            className="fa fa-angle-left text-white"
            style={{
              fontSize: "30px",
            }}
          ></i>
        </span>
      </div>
    </div>
  );
};

export default BlogSlider;
