import React from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const links = [
    { name: "Home", link: "/" },
    { name: "Blog", link: "/blogs" },
    { name: "Practice Areas", link: "/practice-areas" },
    { name: "Career", link: "/career" },
    { name: "Our People", link: "/our-people" },
    { name: "Contact", link: "/contact" },
  ];

  const navigate = useNavigate();

  return (
    <>
      <header className="absolute z-10 py-6 bg-transparent w-full font-montserrat md:block hidden">
        <nav className="flex items-center justify-between p-4 md:text-sm text-xs tracking-wide">
          <div className="flex items-center gap-6 text-gray-300 lg:ml-36 md:ml-10 ml-2">
            {links.slice(0, 3).map((item, index) => (
              <p
                key={index}
                onClick={() => navigate(item.link)}
                className="hover:text-white pb-[0.5px] hover:border-b-2 hover:border-white transition-transform duration-200 hover:scale-110 cursor-pointer"
              >
                {item.name}
              </p>
            ))}
          </div>

          <img src="images/logo.png" className="w-[13%]" />

          <div className="flex items-center gap-6 text-gray-300 lg:mr-36 md:mr-10 mr-2">
            {links.slice(3).map((item, index) => (
              <p
                key={index}
                onClick={() => navigate(item.link)}
                className="hover:text-white pb-[0.5px] hover:border-b-2 hover:border-white transition-transform duration-200 hover:scale-110 cursor-pointer"
              >
                {item.name}
              </p>
            ))}
          </div>
        </nav>
      </header>

      <header className="absolute z-10 py-10 bg-transparent w-full font-montserrat flex flex-col items-center md:hidden">
        <img src="images/logo.png" className="w-[20%] mb-2" />
        <nav className="flex items-center justify-between p-4 md:text-sm text-xs tracking-wide">
          <div className="flex items-center gap-2 text-gray-300 text-center mx-auto">
            {links.map((item, index) => (
              <p
                key={index}
                onClick={() => navigate(item.link)}
                className="hover:text-white pb-[0.5px] hover:border-b-2 hover:border-white transition-transform duration-200 hover:scale-110 cursor-pointer"
              >
                {item.name}
              </p>
            ))}
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
