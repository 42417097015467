import React from "react";
import Appointment from "../components/Appointment";

const Career = () => {
  return (
    <>
      <section className="w-full pt-40 pb-44 bg-cover bg-[url('/public/images/career.png')]">
        <div className="w-full mt-20 flex flex-col justify-center items-center font-bold text-center">
          <h1 className="lg:w-[35%] md:w-[40%] mb-5 w-2/3 font-manrope lg:text-4xl text-3xl text-white">
            APPLY NOW
          </h1>

          <div className="flex items-center justify-center input-background rounded-lg m-10">
            <div className="flex flex-col items-center p-6 rounded-md shadow-lg">
              <input
                type="text"
                placeholder="Enter your name"
                class="input-background mb-2  w-full py-4 px-5 rounded-md focus:outline-none text-white font-montserrat text-xs"
              />
              <input
                type="text"
                placeholder="Enter your phone number"
                class="input-background mb-2 w-full py-4 px-5 rounded-md focus:outline-none text-white font-montserrat text-xs"
              />
              <label className="block font-montserrat-semibold mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Upload your CV
              </label>
              <input
                type="file"
                className="block w-full font-montserrat-semibold text-sm text-gray-900
                input-background rounded-lg border border-gray-300 cursor-pointer
                focus:outline-none focus:border-transparent p-2.5"
                id="file_input"
              />
              <button className="mt-5 px-7 py-3 bg-[#D14049] rounded-md hover:bg-red-500 font-montserrat-semibold text-white text-sm tracking-wide space-x-4">
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>

      <Appointment />
    </>
  );
};

export default Career;
