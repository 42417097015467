import React from "react";
import Appointment from "../components/Appointment";

const Contact = () => {
  return (
    <>
      <section className="w-full py-20 bg-cover bg-black"></section>
      <Appointment />
    </>
  );
};

export default Contact;
