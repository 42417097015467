import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BlogSlider from "../components/BlogSlider";

const Service = () => {
  const [services, setServices] = useState({});

  const location = useLocation();
  const service_key = location.state ? location.state.service : null;
  const category = location.state ? location.state.category : null;

  useEffect(() => {
    fetch(
      category === "corporate"
        ? "/corporate-services.json"
        : "/litigation-services.json"
    )
      .then((response) => response.json())
      .then((data) => setServices(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <>
      <section className="w-full pt-40 pb-36 bg-center bg-cover bg-[url('/public/images/bg.png')]">
        <div className="w-full mt-28 flex flex-col items-center font-bold lg:px-44 md:px-20 px-6">
          <h1 className="uppercase font-manrope lg:text-4xl md:text-3xl text-2xl text-white text-center">
            {service_key}
          </h1>
        </div>
      </section>

      <section className="md:py-10 lg:px-14 md:px-8 p-8 flex md:flex-row flex-col font-montserrat-semibold">
        <div className="md:mb-0 mb-8">
          <img
            className="object-contain w-full h-auto"
            src="/images/placeholder-2.png"
            alt="placeholder"
          />
        </div>

        <div className="md:px-10 md:w-[70%]">
          <p className="font-bold text-base tracking-wide mb-2">SUMMARY</p>
          <p className="text-sm text-gray-600 mb-6">
            {services[service_key] && services[service_key].summary}
          </p>

          {services[service_key] &&
            services[service_key].content.map((item, index) => (
              <>
                <p className="font-bold text-base tracking-wide mb-2">
                  {item.heading}
                </p>
                <p className="text-sm text-gray-600 mb-4">{item.body}</p>
                <ul className="list-disc pl-5 mt-2 mb-6 text-sm text-gray-600">
                  {item.points?.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
              </>
            ))}

          <p className="text-sm text-gray-600 mb-2">
            For more informaion, mail us at:
          </p>
          <button className="py-4 px-5 text-white text-sm bg-[#142247] rounded-md">
            info@siaalaw.com
          </button>
        </div>
      </section>

      {/* <BlogSlider /> */}
    </>
  );
};

export default Service;
