import React from "react";
import { useLocation } from "react-router-dom";

const Member = () => {
  const location = useLocation();
  const member = location.state ? location.state.member : null;

  return (
    <>
      <section className="w-full py-20 bg-cover bg-black"></section>

      <section className="bg-slate-100 md:p-10 p-5">
        <div className="bg-white rounded-lg shadow-sm flex lg:flex-row flex-col items-center">
          <div className="flex flex-col justify-center md:items-start items-center lg:w-1/2 w-full md:px-16 pt-16 lg:pb-16 px-10 font-montserrat-semibold">
            <h2 className="text-3xl font-semibold uppercase md:text-start text-center">
              {member && member.name}
            </h2>
            <span className="text-red-500 mt-3 mb-6 text-sm font-semibold uppercase">
              {member && member.title}
            </span>

            {member && member.notice && (
              <span className="text-black mb-6 text-sm font-semibold uppercase">
                {member.notice}
              </span>
            )}

            <span className="text-black mb-4 text-base font-semibold">
              OVERVIEW
            </span>
            <p
              className="md:text-sm text-xs text-gray-500 font-semibold mb-8 md:text-start text-center"
              dangerouslySetInnerHTML={{
                __html: member ? member.description : "",
              }}
            ></p>

            <span className="text-black mb-4 text-base font-semibold">
              BARS AND COURTS
            </span>
            {member &&
              member["bar affiliations"].map((content, index) => (
                <p
                  key={index}
                  className="md:w-3/4 w-full md:text-start text-center md:text-sm text-xs text-gray-500 border-b-2 border-gray-300 pb-2 font-semibold mb-3"
                >
                  {content}
                </p>
              ))}

            <span className="text-black mt-6 mb-4 text-base font-semibold">
              EDUCATION
            </span>
            {member &&
              member.education.map((content, index) => (
                <p
                  key={index}
                  className="md:w-3/4 w-full md:text-start text-center md:text-sm text-xs text-gray-500 border-b-2 border-gray-300 pb-2 font-semibold mb-3"
                >
                  {content}
                </p>
              ))}

            <span className="text-black mb-4 mt-4 text-base font-semibold">
              CONNECT AT
            </span>

            <div className="flex md:flex-row flex-col md:items-start items-center justify-center">
              <button className="px-6 py-3 mr-2 border-2 text-blue-500 hover:text-white border-blue-500 rounded-md hover:bg-blue-500 text-xs">
                Linkedin
              </button>

              {member?.email && (
                <a
                  href={`mailto:${member.email}`}
                  className="px-6 py-3 md:mt-0 mt-2 border-2 text-gray-500 hover:text-white border-gray-500 rounded-md hover:bg-gray-500 text-xs"
                >
                  {member.email}
                </a>
              )}
            </div>
          </div>
          <img
            alt={member && member.name}
            className="lg:w-1/2 h-max-[200px] w-[90%] object-cover md:m-16 m-10 border-[0.5px] rounded-xl shadow-inner"
            src={member && member.image}
          />
        </div>
      </section>
    </>
  );
};

export default Member;
