import React from "react";

const Founder = () => {
  return (
    <>
      <section className="w-full py-20 bg-cover bg-black"></section>

      <section className="bg-slate-100 p-10">
        <div className="bg-white rounded-lg shadow-sm flex lg:flex-row flex-col items-center">
          <div className="flex flex-col justify-center items-start lg:w-2/3 w-full md:px-16 pt-16 lg:pb-16 px-10 font-montserrat-semibold">
            <span className="text-red-500 text-xs font-semibold">
              OUR BELOVED FOUNDER
            </span>
            <h2 className="text-3xl font-semibold mt-3 mb-6">
              LATE SYED ISHTIAQ AHMED
            </h2>

            <span className="text-black mb-2 text-sm font-semibold">
              LEGAL PIONEER: A LEGACY OF EXCELLENCE
            </span>
            <p className="md:text-sm text-xs text-gray-500 font-semibold mb-8">
              Syed Ishtiaq Ahmed was called to the Bar of the Honourable Society
              of Lincoln’s Inn in 1958 and started practising law in erstwhile
              East Pakistan, now Bangladesh, in 1960s. He was enrolled as an
              Advocate of the Appellate Division of the Supreme Court of
              Bangladesh and in recognition of his excellence in legal practice,
              Mr. Ahmed was conferred the designation of a Senior Advocate.
              Renowned as a distinguished litigator, he carved an indelible mark
              in the legal fraternity of Bangladesh, earning reverence for his
              contributions, including serving as amicus curiae in numerous
              cases throughout his illustrious career and having argued cases
              that have been reported and serves as landmark precedents. Mr.
              Ahmed assumed the role of Additional Attorney General in 1972,
              followed by his appointment as the Attorney General of Bangladesh
              in 1976. His commitment to public service extended globally when
              he became the Permanent Representative of Bangladesh to the United
              Nations in 1978. Simultaneously, he was elected President of the
              Supreme Court Bar Association, a position he held again in
              1989-1990.
            </p>

            <span className="text-black mb-2 text-sm font-semibold">
              GLOBAL LEADERSHIP: HIS JOURNEY IN LAW AND SERVICE
            </span>
            <p className="md:text-sm text-xs text-gray-500 font-semibold mb-8">
              Mr. Ahmed’s significant impact on the legal and political
              landscape continued as he took on ministerial roles in two interim
              non-party caretaker governments of Bangladesh. In 1996, he served
              as the minister in charge of the Ministry of Law & Justice and the
              Ministry of Local Government & Rural Development. Later, in 2001,
              he held the positions of minister in charge of the Ministry of Law
              & Justice and the Ministry of Civil Aviation and Tourism. He also
              played pivotal advisory roles, serving as an advisor to the
              caretaker government of Bangladesh in 1991 and 2001.
            </p>

            <p className="md:text-sm text-xs text-gray-500 font-semibold">
              His demise in July 2003 marked the end of an era, leaving behind a
              legacy of more than four decades of dedicated legal and public
              service. Since Mr. Ahmed’s passing, the law firm has been steered
              by Nihad Kabir, Syed Afzal Hasan Uddin, and Md. Asaduzzaman. Each,
              having benefited from a longstanding association with Mr. Ahmed,
              is unwavering in their commitment to uphold the standards of
              excellence and integrity set by this eminent legal figure.
            </p>
          </div>
          <div>
            <img
              className="rounded-md md:p-16 p-10 object-contain w-full h-auto"
              src="/images/ishtiaq-portrait.png"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Founder;
