import React from "react";

const Terms = () => {
  return (
    <>
      <section className="w-full py-20 bg-cover bg-black"></section>

      <section className="bg-slate-100 p-10">
        <div className="bg-white rounded-lg shadow-sm font-montserrat-semibold">
          <h1 className="border-b-2 border-gray-300 text-center md:text-2xl text-xl mx-2 pt-10 pb-5 font-semibold">
            <span className="text-red-500">SIA&A -</span> PRIVACY & COOKIE
            STATEMENT
          </h1>
          <div className="text-sm text-gray-500 md:px-32 px-10 py-14">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <ul className="list-disc pl-5 mb-6">
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </li>
            </ul>
            <span className="text-black text-base font-semibold">Overview</span>
            <p className="mt-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <table className="my-10">
              <tr>
                <td className="text-black font-bold tracking-wide">
                  Collection
                </td>
                <td>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <a href="#" className="underline text-red-500">
                    Click here to learn more
                  </a>
                </td>
              </tr>

              <tr>
                <td className="text-black font-bold tracking-wide">Use</td>
                <td>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <a href="#" className="underline text-red-500">
                    Click here to learn more
                  </a>
                </td>
              </tr>

              <tr>
                <td className="text-black font-bold tracking-wide">Sharing</td>
                <td>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <a href="#" className="underline text-red-500">
                    Click here to learn more
                  </a>
                </td>
              </tr>

              <tr>
                <td className="text-black font-bold tracking-wide">
                  Marketing Choices
                </td>
                <td>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <a href="#" className="underline text-red-500">
                    Click here to learn more
                  </a>
                </td>
              </tr>

              <tr>
                <td className="text-black font-bold tracking-wide">Cookies</td>
                <td>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <a href="#" className="underline text-red-500">
                    Click here to learn more
                  </a>
                </td>
              </tr>

              <tr>
                <td className="text-black font-bold tracking-wide">
                  Data Subject Rights
                </td>
                <td>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <a href="#" className="underline text-red-500">
                    Click here to learn more
                  </a>
                </td>
              </tr>

              <tr>
                <td className="text-black font-bold tracking-wide">
                  Data Security
                </td>
                <td>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <a href="#" className="underline text-red-500">
                    Click here to learn more
                  </a>
                </td>
              </tr>

              <tr>
                <td className="text-black font-bold tracking-wide">
                  Cross-Border Data Transfers
                </td>
                <td>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <a href="#" className="underline text-red-500">
                    Click here to learn more
                  </a>
                </td>
              </tr>

              <tr>
                <td className="text-black font-bold tracking-wide">
                  Other Issues
                </td>
                <td>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <a href="#" className="underline text-red-500">
                    Click here to learn more
                  </a>
                </td>
              </tr>

              <tr>
                <td className="text-black font-bold tracking-wide">
                  Contact Us
                </td>
                <td>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <a href="#" className="underline text-red-500">
                    Click here to learn more
                  </a>
                </td>
              </tr>
            </table>
            <span className="text-black mb-4 text-base font-semibold">
              Overview
            </span>
            <p className="mt-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <ul className="list-disc pl-5">
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </li>
            </ul>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
